<template>
 <div>
  <svg viewBox="0 0 183 183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="userSpaceOnUse" id="Naamloos_verloop_4" x1="31.29" x2="151.71" y1="91.5" y2="91.5">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g data-name="Laag 2" id="Laag_2">
    <g data-name="Laag 1" id="Laag_1-2">
     <rect :fill="background" height="183" width="183"/>
     <path d="M136.71,65.55H106.48a17.56,17.56,0,0,0-1.76-7.5c-.26-.54-.54-1-.83-1.53s-.63-1-1-1.45-.72-.9-1.1-1.32h0c-.4-.42-.79-.82-1.22-1.22h0c-.42-.38-.86-.75-1.32-1.1s-.94-.67-1.45-1a17.13,17.13,0,0,0-1.53-.83,18,18,0,0,0-15.59,0c-.53.26-1,.53-1.53.83a16.45,16.45,0,0,0-1.44,1c-.46.35-.9.72-1.32,1.1h0c-.42.4-.83.8-1.23,1.22h0c-.38.42-.75.86-1.09,1.32s-.67,1-1,1.45a17.07,17.07,0,0,0-.82,1.53,17.43,17.43,0,0,0-1.77,7.5H46.29a15,15,0,0,0-15,15v39.6a15,15,0,0,0,15,15h90.42a15,15,0,0,0,15-15V80.55A15,15,0,0,0,136.71,65.55Zm-62,43.78H70.6v-8.18l.13-5.48h-.06l-4.39,13.66H62.53L58.12,95.67h0l.14,5.48v8.18H54.08V91.51h6.6l2.81,9.09,1,3.77h.05l1-3.76,2.77-9.1H74.7Zm1.81-43.78a12,12,0,0,1,24,0,2.81,2.81,0,0,1,0,.3,12,12,0,0,1-24,0A1.42,1.42,0,0,1,76.51,65.55Zm13.56,43.78-1.22-4H82.7l-1.25,4H76.78L83,91.51H88.6l6.25,17.82Zm14.9,0H99.52L93.17,91.51h4.88l3.28,10.79,1,3.5.94-3.45,3.32-10.84h4.74Zm23.63-4a7.39,7.39,0,0,1-3,3.15,10.44,10.44,0,0,1-9.33,0,7.15,7.15,0,0,1-3-3.15,12,12,0,0,1,0-9.86,7.1,7.1,0,0,1,3-3.15,10.36,10.36,0,0,1,9.33,0,7.33,7.33,0,0,1,3,3.15,12.1,12.1,0,0,1,0,9.86Zm-40.72-3.21H83.67L85,97.86l.72-2.78h.05l.79,2.81Zm37-1.73a7.1,7.1,0,0,1-1,4.14,3.83,3.83,0,0,1-5.92,0,8.72,8.72,0,0,1,0-8.26,3.81,3.81,0,0,1,5.92,0A7,7,0,0,1,124.9,100.42ZM101.82,53.75c.38.42.75.86,1.1,1.32A13.74,13.74,0,0,0,101.82,53.75Zm-2.55-2.33c.46.35.9.72,1.32,1.1A13.74,13.74,0,0,0,99.27,51.42Zm-22.86,1.1c.42-.38.86-.75,1.32-1.1A13.74,13.74,0,0,0,76.41,52.52Zm-2.33,2.55c.34-.46.71-.9,1.09-1.32A15.27,15.27,0,0,0,74.08,55.07ZM90,65.24v6.4a1.5,1.5,0,0,1-3,0v-6.4a1.5,1.5,0,0,1,3,0Zm0-5.19a1.42,1.42,0,0,1-.44,1.06,1.45,1.45,0,0,1-1.06.44,1.56,1.56,0,0,1-.57-.11,1.76,1.76,0,0,1-.49-.33A1.54,1.54,0,0,1,87,60.05a1.45,1.45,0,0,1,0-.29,1.47,1.47,0,0,1,.08-.28,1.18,1.18,0,0,1,.15-.25.7.7,0,0,1,.17-.24,1.55,1.55,0,0,1,1.36-.41l.29.09a2.1,2.1,0,0,1,.26.13,1.9,1.9,0,0,1,.21.19.75.75,0,0,1,.19.24,1.18,1.18,0,0,1,.15.25c0,.09.06.19.08.28A2.83,2.83,0,0,1,90,60.05Z" style="fill:url(#Naamloos_verloop_4);"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>